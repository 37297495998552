body {
  color: $black;
  font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  &.hidden {
    overflow: hidden;
  }

  @media (min-width: $media-992) {
    font-size: 16px;
  }
}

.main-page {
  display: flex;
  flex-flow: row wrap;

  .main {
    flex: 1 0;
    padding: 15px;
    min-height: calc(100vh - 66px);

    @media (min-width: $media-992) {
      padding: 15px 25px;
    }
  }
}

article {
  margin-bottom: 20px;
}

p {
  margin-bottom: 14px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

/* Image */
.responsive-image {
  max-width: 280px;

  @media (min-width: $media-576) {
    max-width: 470px;
  }
  @media (min-width: $media-768) {
    max-width: 400px;
  }
  @media (min-width: $media-992) {
    max-width: 600px;
  }
  @media (min-width: $media-1240) {
    max-width: none;
  }
}

pre {
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  white-space: pre-wrap;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}
  
